<template>
    <div class="content">
        <main>
            <h1 class="title has-text-white has-text-weight-light is-size-1 is-size-2-mobile">{{ $t("navigation.about") }}</h1>
            <section class="section about my-6">
                <h2 class="title has-text-white has-text-weight-light is-size-2 is-size-3-mobile">{{ $t("message.title") }}</h2>
                <p class="is-size-4-desktop is-size-5-tablet">
                    {{ $t("message.about.seenjab_desc") }}
                </p>
            </section>
            <section class="section about my-6">
                <h2 class="title has-text-white has-text-weight-light is-size-2 is-size-3-mobile">{{ $t("message.about.assistant") }}</h2>
                <p class="is-size-4-desktop is-size-5-tablet">
                    {{ $t("message.about.assistant_desc") }}
                </p>
            </section>
            <section class="section about my-6">
                <h2 class="title has-text-white has-text-weight-light is-size-2 is-size-3-mobile">{{ $t("message.about.security") }}</h2>
                <p class="is-size-4-desktop is-size-5-tablet">
                    {{ $t("message.about.security_desc") }}
                </p>
            </section>
        </main>
    </div>
</template>

<script>
/**
 * About view
 * Display the About page
 * @author Alba IT
 * @version 1.0.0
 */
export default {
    name: 'About',
    metaInfo: function() {
        return {
            title: `${this.$t("message.about.meta.title")} | ${this.$t('message.title')}`,
            htmlAttrs: {
                lang: (this.$store.getters['user/getLocale'] != '') ? this.$store.getters['user/getLocale'] : 'en',
                //amp: false
            },
            meta: [
                {
                    name: 'description',
                    content: this.$t('message.about.meta.description')
                },
				{
					name: 'author',
					content: this.$t('message.meta.author')
				},
                {
                    name: 'og:title',
                    content: `${this.$t("message.about.meta.title")} | ${this.$t('message.title')}`
                },
                {
                    name: 'og:image',
                    content: 'https://seenjab.com/img/logo_small.png'
                },
                {
                    name: 'og:image:secure_url',
                    content: 'https://seenjab.com/img/logo_small.png'
                },
                {
                    name: 'og:image:type',
                    content: 'image/png'
                },
                {
                    name: 'og:image:alt',
                    content: 'Seenjab logo small'
                },
                {
                    name: 'og:description',
                    content: this.$t('message.about.meta.description'),
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

@media(min-width: $desktop-landscape) {
    .about {
        width: 50%;
        margin: auto;
    }
}
</style>
